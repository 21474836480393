import React from "react"
import SEO from "components/seo"
import TriageDetails from "images/triage-details.png"
import PotentialSolutions from "images/sentinel-solutions.png"
import SentinelPR from "images/sentinel-pr.png"
import SentinelVideo from "images/Sentinel.mp4"
import AnchorButton from "components/anchor-button"
import CenteredAnchorButton from "components/centered-anchor-button"
import PropTypes from "prop-types"

/**
 * Reusable CTA Button Section
 */
const HeroCTA = () => (
  <div className="flex items-left flex-row gap-4 max-w-sm">
    <AnchorButton
      href="https://research.typeform.com/to/KycOIhlo"
      className="
        px-6 py-3 text-center
        bg-orange-500 text-white font-semibold
        rounded-md shadow
        hover:bg-orange-600
        hover:shadow-lg
        transition-colors
      "
    >
      Join the waitlist
    </AnchorButton>
  </div>
)

const StandardCTA = () => (
  <div className="flex justify-center">
    <div className="flex justify-center">
      <CenteredAnchorButton
        href="https://research.typeform.com/to/KycOIhlo"
        className="
          px-6 py-3 text-center
          bg-orange-500 text-white font-semibold
          rounded-md shadow
          hover:bg-orange-600
          hover:shadow-lg
          transition-colors
          w-full
        "
      >
        Join the waitlist
      </CenteredAnchorButton>
    </div>
  </div>
)

const CheckBullet = ({ children }) => (
  <li className="flex items-start gap-2 text-lg leading-relaxed">
    <svg
      className="h-5 w-5 text-orange-500 flex-shrink-0"
      fill="none"
      stroke="currentColor"
      strokeWidth="3"
      viewBox="0 0 24 24"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
    </svg>
    <span>{children}</span>
  </li>
)

CheckBullet.propTypes = {
  children: PropTypes.node.isRequired,
}

const SentinelPage = () => {
  return (
    <div>
      <SEO
        title="Sourcery Sentinel | Your Always On-Call Engineer"
        description="Sentinel is the AI agent to automatically fix issues detected by Sentry"
        keywords={[
          "sourcery",
          "sentinel",
          "production issues",
          "sentry",
          "bug fix",
          "developer tools",
          "python",
          "javascript",
          "typescript",
          "ai",
        ]}
      />

      {/* HERO SECTION */}
      <section className="bg-black text-white overflow-hidden pt-[88px] md:pt-[96px]">
        <div className="max-w-7xl mx-auto px-4 pt-16 pb-16">
          <div className="flex flex-col md:flex-row items-center justify-between gap-8">
            {/* Left - Headline and CTA */}
            <div className="w-full md:w-1/2">
              <h1 className="text-5xl md:text-6xl font-extrabold mb-6 leading-tight">
                Meet Sentinel — Your always on-call engineer
              </h1>
              <p className="text-lg md:text-xl mb-8 max-w-xl leading-relaxed text-gray-100">
                Sentinel by Sourcery is here to make it easier to handle
                production issues. It is always watching your Sentry issues and
                springs into action to fix them as soon as a new issue is
                spotted.
                <br />
                <br />
                <em>
                  Sentinel will be available for general access in the coming
                  weeks.
                </em>
              </p>
              <HeroCTA />
            </div>

            {/* Right - Video */}
            <div className="w-full md:w-1/2">
              <div className="relative rounded-xl overflow-hidden shadow-lg">
                <video
                  src={SentinelVideo}
                  controls
                  playsInline
                  aria-label="Feature Highlight"
                  className="w-full h-auto rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FEATURES SECTION */}
      <section className="bg-black-500 text-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          {/* Feature 1 */}
          <div className="flex flex-col md:flex-row items-center justify-between mt-6 mb-10 gap-8">
            <div className="md:w-1/2">
              <h3 className="text-3xl font-bold mb-4">
                Triage and diagnose every Sentry issue
              </h3>
              <ul className="space-y-3">
                <CheckBullet>
                  Immediately figure out which issues to focus on, which to
                  ignore, and which Sentinel can fix
                </CheckBullet>
                <CheckBullet>
                  Break down potential root causes of each issue
                </CheckBullet>
                <CheckBullet>
                  Identify where in your codebase (or outside of the code) an
                  issue is coming from
                </CheckBullet>
              </ul>
            </div>
            <div className="md:w-1/2">
              <img
                src={TriageDetails}
                alt="Triage Details"
                className="
                  rounded-xl shadow-lg
                  transition-transform duration-200
                  hover:scale-105
                "
              />
            </div>
          </div>

          {/* Feature 2 (reverse layout on desktop) */}
          <div className="flex flex-col md:flex-row items-center justify-between mb-10 gap-8">
            <div className="md:w-1/2">
              <img
                src={PotentialSolutions}
                alt="Potential Sentinel Solutions"
                className="
                    rounded-xl shadow-lg
                    transition-transform duration-200
                    hover:scale-105
                    "
              />
            </div>
            <div className="md:w-1/2">
              <h3 className="text-3xl font-bold mb-4">
                Find the right path to fix an issue
              </h3>
              <ul className="space-y-3">
                <CheckBullet>
                  Sentinel explores multiple solution paths to find the right
                  one
                </CheckBullet>
                <CheckBullet>
                  Review different solutions to figure out which approach makes
                  the most sense for your needs
                </CheckBullet>
                <CheckBullet>Choose the right fix for your project</CheckBullet>
              </ul>
            </div>
          </div>

          {/* Feature 3 */}
          <div className="flex flex-col md:flex-row items-center justify-between mb-10 gap-8">
            <div className="md:w-1/2">
              <h3 className="text-3xl font-bold mb-4">Apply a fix PR</h3>
              <ul className="space-y-3">
                <CheckBullet>
                  Sentinel creates a PR for the fix you want
                </CheckBullet>
                <CheckBullet>
                  Tell Sentinel any changes or improvements you’d want it to
                  make to the PR
                </CheckBullet>
                <CheckBullet>
                  Merge in a fix and watch the issue go away
                </CheckBullet>
              </ul>
            </div>
            <div className="md:w-1/2">
              <img
                src={SentinelPR}
                alt="Pull Request from Sentinel"
                className="
                  rounded-xl shadow-lg
                  transition-transform duration-200
                  hover:scale-105
                "
              />
            </div>
          </div>
        </div>
      </section>

      {/* FINAL CTA SECTION */}
      <section className="bg-black text-white py-16">
        <div className="max-w-7xl mx-auto px-4 flex flex-col items-center text-center">
          <h2 className="text-4xl md:text-5xl font-extrabold mb-4">
            Fix issues faster
          </h2>
          <p className="text-lg md:text-xl mb-8">
            Get early access to Sentinel.
          </p>
          {/* Ensures CTA button is centered */}
        </div>
        <StandardCTA />
      </section>
    </div>
  )
}

export default SentinelPage
