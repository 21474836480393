import React from "react"
import PropTypes from "prop-types"

const AnchorButton = ({ children, icon, href }) => {
  return (
    <a
      href={href}
      className="flex w-[360px] justify-between mx-auto items-center font-bold text-black-500 text-lg/[normal] -tracking-[0.0225rem]"
    >
      <div
        className={`flex h-[3rem] justify-center items-center text-center bg-orange-200 md:gap-[8.834px] xl:gap-[12px] px-6 md:rounded-[1.472px] xl:rounded-sm hover:bg-opacity-90`}
      >
        {icon}
        <p className="text-black-500 text-center">{children}</p>
      </div>
    </a>
  )
}

AnchorButton.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  href: PropTypes.string.isRequired,
}

export default AnchorButton
